@use '~@myjobglasses/account-ui/mixins/colors';

$freemium-gradient: linear-gradient(180deg, #82dafa, #00a8e5);
$premium-gradient: linear-gradient(180deg, #fdf3d6, #d3a836);

$freemium-color: #09193b;
$premium-color: #d3a836;

.ambassador-card {
  display: flex;
  flex-direction: column;
  width: 230px;
  height: 313px;
  color: colors.$primary;
  text-decoration: none !important;

  &__header {
    height: 102px;
    background: url(https://assets.myjobglasses.com/images/card-background-fioritures.svg), $freemium-gradient;
    border-radius: 24px 24px 0 0;
    z-index: 0;

    &--premium {
      background: url(https://assets.myjobglasses.com/images/card-background-fioritures.svg), $premium-gradient;
    }
  }

  &__content {
    position: relative;
    flex: 1;
    padding: 64px 24px 24px;
    display: flex;
    flex-direction: column;
    background-color: colors.$white;
    border-radius: 0 0 24px 24px;
    z-index: 1;

    &__avatar {
      position: absolute;
      left: 50%;
      bottom: 80%;
      width: 100px;
      height: 100px;
      background-color: colors.$white;
      border-radius: 50%;
      border: 3px solid colors.$white;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      object-fit: cover;
      transform: translate(-50%);
    }

    &__company-logo {
      position: absolute;
      left: 50%;
      top: calc(25px / 2);
      display: flex;
      justify-content: center;
      align-items: center;
      width: 40px;
      height: 40px;
      background-color: colors.$white;
      border: 3px solid colors.$white;
      border-radius: 50%;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
      transform: translate(-50%);

      > svg {
        width: 28px;
        height: 28px;
        stroke: colors.$grey;
      }
    }

    &__name {
      font-size: 18px;
      font-weight: bold;
    }

    &__company {
      font-size: 10px;
      font-weight: 600;
      letter-spacing: 1px;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__position {
      margin-top: 16px;
      font-size: 12px;
      display: -webkit-box;
      line-clamp: 2;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }

  &__footer {
    flex: 1;
    margin-top: 8px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    &__availability {
      display: flex;
      align-items: center;
      column-gap: 8px;
      font-size: 11px;
      font-weight: 700;
      color: $freemium-color;

      &--premium {
        color: $premium-color;
      }

      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: calc(24px + 8px);
        height: calc(24px + 8px);
        border: 2px solid $freemium-color;
        border-radius: 50%;

        & > svg {
          width: 18px;
          height: 18px;
          stroke: $freemium-color;
          stroke-width: 2.5px;
        }

        &--premium {
          border-color: $premium-color;

          & > svg {
            stroke: colors.$white;
            fill: $premium-color;
            stroke-width: 1px;
          }
        }

        &--unavailable {
          background-color: $freemium-color;

          & > svg {
            stroke: $freemium-color;
            fill: colors.$white;
          }
        }

        &--premium-unavailable {
          background-color: $premium-color;

          & > svg {
            stroke: $premium-color;
            fill: colors.$white;
          }
        }
      }
    }

    &__likes {
      display: flex;
      align-items: center;
      column-gap: 8px;
      font-size: 12px;
      font-weight: bold;
      color: $premium-color;

      &__icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 28px;
        height: 28px;
        background-color: $premium-color;
        border-radius: 50%;

        & > svg {
          width: 16px;
          height: 16px;
          fill: colors.$white;
          stroke: $premium-color;
          stroke-width: 2px;
        }
      }
    }
  }
}
