$check-icon-size: 24px;
$header-column-gap: 16px;

.expandable-completion-block {
  display: flex;
  flex-direction: column;
  padding: 16px 32px 16px 16px;
  border: 1px solid #E6E8EB;
  border-radius: 8px;

  &__header {
    display: flex;
    column-gap: $header-column-gap;
    align-items: center;
    font-size: 12px;
    color: #7E7E7E;
    cursor: pointer;
    background-color: transparent;
    border: none;
    stroke-width: 2px;

    &__check {
      display: flex;
      align-items: center;
      justify-content: center;
      width: $check-icon-size;
      height: $check-icon-size;
      border: 1px solid #D9D9D9;
      border-radius: 50%;
      stroke: #D9D9D9;
      transition-timing-function: ease-in-out;
      transition-duration: 200ms;
      transition-property: border-color, background-color;

      > svg {
        width: 16px;
        height: 16px;
        margin-top: 1px;
      }
    }

    > label {
      flex: 1;
      margin: 0;
      font-weight: normal;
      text-align: start;
      cursor: inherit;
    }

    &__chevron {
      width: 16px;
      height: 16px;
      stroke: #888888;
      transition: transform 200ms ease-in-out;
    }
  }

  &__content, &__footer {
    margin-left: calc($check-icon-size + $header-column-gap);
  }

  &__footer {
    &__actions {
      display: flex;
      align-items: center;
      justify-content: space-between;

      &__see-example {
        display: flex;
        column-gap: 8px;
        align-items: center;
        font-size: 12px;
        color: #09193B;
        text-decoration: underline;
        background-color: transparent;
        border: none;

        > svg {
          width: 16px;
          height: 16px;
          stroke: #09193B;
          stroke-width: 2px;
          transition: transform 200ms ease-in-out;
        }

        &--active {
          > svg {
            transform: rotateX(180deg);
          }
        }
      }
    }

    &__example {
      padding: 14px 16px;
      margin: 16px 0 0;
      font-size: 12px;
      background-color: #F5F3F3;
      border-radius: 8px;
    }
  }

  &--disabled {
    .expandable-completion-block {
      &__header {
        cursor: not-allowed;
      }
    }
  }

  &--expanded {
    border-color: #8E95A5;

    .expandable-completion-block {
      &__header {
        &__check {
          border-color: #8E95A5;
          stroke: #8E95A5;
        }

        > label {
          color: #09193B;
        }

        &__chevron {
          transform: rotateX(180deg);
        }
      }
    }
  }

  &--checked {
    border-color: #8E95A5;

    .expandable-completion-block {
      &__header {
        &__check {
          background-color: #09193B;
          border-color: #09193B;
          stroke: white;
        }

        > label {
          color: #09193B;
        }
      }
    }
  }
}