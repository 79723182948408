@use '~@myjobglasses/account-ui/mixins/colors';

$avatar-size: 256px;
$small-avatar-size: calc(#{$avatar-size} / 2.5);

.label {
  font-size: 14px;
  font-weight: 400;
  color: colors.$primary;
}

.avatar-input {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 32px;
  align-items: center;
  justify-content: center;
  width: 100%;

  &--small {
    flex-direction: row;
    column-gap: 14px;
    justify-content: flex-start;

    .avatar-input {
      &__viewer {
        width: $small-avatar-size;
        height: $small-avatar-size;
        &__placeholder-icon {
          width: 36px;
          height: 36px;
        }
      }
    }
  }

  &__viewer {
    display: flex;
    align-items: center;
    justify-content: center;
    width: $avatar-size;
    height: $avatar-size;
    padding: 0;
    text-align: start;
    cursor: pointer;
    background-color: colors.$grey;
    border: none;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(colors.$primary, 0.1);
    transition: background-color 100ms ease-in-out;

    &__placeholder-icon {
      z-index: 10;
      width: 48px;
      height: 48px;
      stroke: colors.$white;
      stroke-width: 2;
    }

    &__avatar {
      width: 100%;
      height: 100%;
      border-radius: 50%;
    }

    &:hover {
      background-color: colors.$dark-grey;
    }
  }
}
