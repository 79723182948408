@use '~@myjobglasses/account-ui/mixins/colors';
@import '../input';

.text-input-with-checker {
  position: relative;

  &__header {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
  }

  @include input(false, false);

  &__main__actions {
    display: flex;
    align-items: center;
    column-gap: 8px;

    > button {
      font-size: 12px;
      font-weight: 500;
      text-decoration: underline;
      background-color: transparent;
      border: none;
    }

    > svg {
      width: 18px;
      stroke: colors.$dark-grey;
      stroke-width: 2px;
    }
  }
}
