@use '~@myjobglasses/account-ui/mixins/colors';

.text-area-input {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: 4px;

  label {
    font-size: 14px;
    font-weight: 400;
    color: colors.$primary;

    &.error {
      color: rgba(colors.$status-error, 0.75);
    }
  }

  &__main {
    display: flex;
    column-gap: 12px;
    align-items: flex-start;
    padding: 16px 12px;
    border: 1px solid colors.$light-grey;
    border-radius: 10px;
    box-shadow: inset 0 0 2px colors.$dark-grey;
    transition-timing-function: ease-in-out;
    transition-duration: 150ms;
    transition-property: border-color, background-color;

    &--focused {
      background-color: colors.$light-grey;
      border-color: colors.$grey;
    }

    &__icon {
      stroke: colors.$dark-grey;
      stroke-width: 1.5;
    }

    textarea {
      flex: 1;
      height: 128px;
      min-height: 64px;
      max-height: 480px;
      font-size: 14px;
      color: colors.$primary;
      background-color: transparent;
      border: none;
      outline: none;

      &::placeholder,
      &.placeholder {
        color: colors.$dark-grey;
      }
    }

    &--error {
      background-color: rgba(colors.$status-error, 0.05);
      box-shadow: inset 0 0 2px colors.$status-error;

      > textarea::placeholder {
        color: rgba(colors.$status-error, 0.75);
      }
    }

    &--disabled {
      background-color: rgba(colors.$grey, 0.5);

      > textarea {
        color: colors.$dark-grey;
      }
    }
  }

  &__error {
    position: absolute;
    top: calc(100% + 8px);
    color: colors.$status-error;
  }

  &__info {
    padding: 16px;
    margin-top: 8px;
    font-size: 12px;
    font-weight: 500;
    background-color: rgba(colors.$grey, 0.5);
    border-radius: 8px;
  }
}
