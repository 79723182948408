.authentication-aside {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  row-gap: 8px;
  height: 80vh;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    row-gap: 8px;

    &__carousel {
      position: relative;
      margin-bottom: 32px;
      text-align: center;

      > img {
        width: min(400px, 100%);
      }
    }

    &__title,
    &__subtitle {
      margin: 0;
      font-size: 16px;
      font-weight: 300;
    }

    &__title {
      font-weight: 500;
    }

    &__dots {
      margin-top: 16px;
      display: flex;
      align-items: center;
      column-gap: 16px;

      > div {
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background-color: #a8a8a8;
        cursor: pointer;

        &.active {
          background-color: #ffffff;
        }
      }
    }
  }

  &__footer {
    margin-top: 24px;
    display: grid;
    align-items: center;
    justify-items: center;
    grid-template-areas:
      'q d d'
      'q a i';
    gap: 8px;

    #qrcode {
      grid-area: q;
      width: 50px;
      height: 50px;
    }

    #download {
      grid-area: d;
      margin: 0;
      text-align: center;
      font-size: 12px;
      font-weight: 500;
    }

    #ios {
      grid-area: i;
      height: 34px;
    }

    #android {
      grid-area: a;
      height: 34px;
    }
  }
}
