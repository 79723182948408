@use '~@myjobglasses/account-ui/mixins/colors';

.badge-radio {
  display: flex;
  flex-direction: column;

  &__label {
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 600;
    color: #0C1D37;
  }

  &__input {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    align-items: center;
    justify-content: start;

    input {
      display: none;
    }

    label {
      width: fit-content;
      padding: 8px 16px;
      font-size: 12px;
      font-weight: 400;
      color: colors.$primary;
      text-align: center;
      cursor: pointer;
      border: 1px solid colors.$primary;
      border-radius: 10px;
    }

    input:checked + label {
      color: white;
      background-color: #FF3460;
      border-color: #FF3460;
    }

    input:checked + label:hover {
      color: #FF3460;
      background-color: white;
      border-color: #FF3460;
    }

    label:hover {
      color: white;
      background-color: #FF3460;
      border-color: #FF3460;
    }
  }
}
