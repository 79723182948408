@use '~@myjobglasses/account-ui/mixins/colors';
@use '~@myjobglasses/account-ui/mixins/buttons';
@use '~@myjobglasses/account-ui/mixins/dimensions';
@use '../../../mixins/page';
@use '../../../mixins/form';

.container {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 50vh;
  img {
    align-self: center;
    width: 144px;
    height: 144px;
  }
  .form {
    @include form.form;
    .emailSent {
      @include buttons.button;

      display: table;
      padding: 0 dimensions.$space-xs;
      margin: 0 auto;
      color: colors.$status-success;
      border: 1px solid colors.$status-success;
    }
    button {
      width: 100%;
    }
  }
}
