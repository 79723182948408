@use '~@myjobglasses/account-ui/mixins/colors';

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.check-icon {
  opacity: 0.5;
  stroke: colors.$status-success;
  stroke-width: 2px;
  animation: fadeIn 250ms ease-out;

  &--error {
    stroke: colors.$status-error;
  }
}

.x-icon {
  opacity: 0.5;
  stroke: colors.$status-error;
  stroke-width: 2px;
  animation: fadeIn 250ms ease-out;

  &--error {
    stroke: colors.$status-error;
  }
}
