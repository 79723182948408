@use '~@myjobglasses/account-ui/mixins/colors';

$freemium-gradient: linear-gradient(180deg, #82dafa, #00a8e5);
$premium-gradient: linear-gradient(180deg, #fdf3d6, #d3a836);

$default-background-url: url(https://assets.myjobglasses.com/images/card-background-fioritures.svg);

.ambassador-view {
  position: absolute;
  top: 20%;
  right: 0;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  width: 80%;
  color: colors.$primary;
  background-color: #f5f5f5;
  border-radius: 8px 0 0 8px;

  &__identity {
    &__banner {
      position: relative;
      height: 164px;
      background: $default-background-url, $freemium-gradient;
      border-radius: 8px 0 0;

      &--premium {
        background: $default-background-url, $premium-gradient;
      }

      &--custom {
        background: none;

        &__image {
          width: 100%;
          height: 100%;
          border-radius: 8px 8px 0 0;
          object-fit: cover;
        }
      }

      &__avatar {
        position: absolute;
        top: 100%;
        left: 32px;
        width: 128px;
        height: 128px;
        background-color: colors.$white;
        border: 2px solid colors.$white;
        border-radius: 50%;
        box-shadow: 0 2px 4px rgba(colors.$primary, 0.1);
        transform: translate(0, -50%);
      }

      &__company-logo {
        position: absolute;
        top: 100%;
        left: 128px;
        width: 64px;
        height: 64px;
        object-fit: contain;
        background-color: colors.$white;
        border: 2px solid colors.$white;
        border-radius: 50%;
        box-shadow: 0 2px 4px rgba(colors.$primary, 0.1);
        transform: translate(0, -50%);
      }
    }

    &__content {
      display: flex;
      flex-direction: column;
      row-gap: 16px;
      padding: 80px 32px 32px;
      background: colors.$white;
      border-radius: 0 0 0 8px;

      &__first-row {
        display: flex;
        flex-direction: row;
        column-gap: 10px;
        align-items: center;

        &__name {
          font-size: 20px;
          font-weight: 600;
        }

        &__star-icon {
          width: 20px;
          height: 20px;
          fill: colors.$white;
          stroke: #ffcd3c;
          stroke-width: unset;

          &--loading {
            cursor: initial;
            fill: #ffcd3c;
          }
          &--filled {
            cursor: pointer;
            fill: #ffcd3c;

            &:hover {
              fill: rgba(#ffcd3c, 0.7);
            }
          }
          &--empty {
            cursor: pointer;
            fill: white;

            &:hover {
              fill: rgba(#ffcd3c, 0.3);
            }
          }
        }
      }

      &__position {
        font-size: 16px;
        font-weight: 500;
      }

      &__company-name {
        font-size: 16px;
        font-weight: 300;
      }

      &__location {
        display: flex;
        column-gap: 8px;
        align-items: center;
        font-size: 16px;
        font-weight: 300;

        > svg {
          width: 16px;
          height: 16px;
          stroke: colors.$primary;
          stroke-width: 2px;
        }
      }
    }
  }

  &__presentation {
    display: flex;
    flex-direction: column;
    row-gap: 20px;
    height: 100%;
    padding: 32px;
    background-color: colors.$white;
    border-radius: 8px 0 0 8px;

    &__title {
      display: flex;
      column-gap: 4px;
      align-items: center;
      font-size: 16px;
      font-weight: 500;
      stroke: colors.$primary;
      stroke-width: 2px;
    }

    &__content {
      display: flex;
      flex-direction: column;
      row-gap: 8px;

      > div > p {
        margin-bottom: 8px;
        font-size: 14px;
      }
    }
  }
}
