@use '~@myjobglasses/account-ui/mixins/colors';

$only-icon-width: 48px;

.primary-button {
  display: flex;
  column-gap: 12px;
  align-items: center;
  justify-content: center;
  height: 48px;
  padding: 0 20px;
  font-size: 14px;
  font-weight: bold;
  color: colors.$white;
  background-color: colors.$primary;
  border: none;
  border-radius: 4px;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
  transition-property: background-color, color;

  &:not(&--disabled):hover {
    background-color: rgba(colors.$primary, 0.75);
  }

  &__icon {
    stroke: colors.$white;
    stroke-width: 1.5;
    transition: stroke 200ms ease-in-out;
  }

  &--only-icon {
    max-width: $only-icon-width;
    padding: 0;

    .secondary-button__icon {
      stroke-width: 2.5;
    }
  }

  &--accent {
    color: colors.$white;
    background-color: colors.$primary;

    &:not(&--disabled):hover {
      background-color: rgba(colors.$primary, 0.75);
    }

    .primary-button__icon {
      stroke: colors.$white;
    }
  }

  &--left-icon {
    flex-direction: row-reverse;
  }

  &--disabled {
    background-color: rgba(colors.$dark-grey, 0.55);
  }

  &--outlined {
    color: colors.$primary;
    background-color: colors.$white;
    border: 1px solid colors.$primary;

    &:not(&--disabled):hover {
      background-color: rgba(colors.$white, 0.1);
    }
  }

  &--succeeded {
    color: colors.$primary;
    background-color: colors.$green;

    .primary-button__icon {
      stroke: colors.$primary;
    }

    &:not(&--disabled):hover {
      background-color: rgba(colors.$green, 0.75);
    }
  }

  &--smaller {
    height: 38px;
    font-size: 12px;
    font-weight: 600;
  }
}
