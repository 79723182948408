@use '~@myjobglasses/account-ui/mixins/colors';

.current-education-panel {
  &__period {
    display: flex;
    column-gap: 8px;

    & > * {
      flex: 1;
    }
  }
}

.apprentice-radio-field {
  padding: 16px;
  background-color: #F5F5F5;
  border-radius: 8px;
}
