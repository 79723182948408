.profile-completion-aside {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  align-items: center;
  width: min(290px, 80%);
  height: min(80%, 520px);
  padding: 32px 20px;
  color: #09193b;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0 1px 4px #eee;

  &__avatar {
    width: 120px;
    height: 120px;
    border-radius: 50%;

    &--placeholder {
      border-radius: 0;
    }
  }

  &__name {
    font-size: 16px;
    font-weight: 500;
  }

  &__infos {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    align-items: center;

    > p {
      margin: 0;
      font-size: 12px;
      text-align: center;
    }
  }

  &__presentation {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 24px 0;
    border-top: 1px solid #d9dcea;

    &__title {
      margin-bottom: 16px;
      font-size: 12px;
      font-weight: 500;
    }

    > p {
      margin: 0;
    }
  }

  &--want-to-share {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 200px;

    &__title {
      margin-top: 56px;
      font-size: 16px;
      font-weight: 500;
    }

    > p {
      font-size: 16px;
      font-weight: 300;
    }
  }
}
