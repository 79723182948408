@use '~@myjobglasses/account-ui/mixins/colors';
.toggle-container {
  display: flex;
  align-items: center;
	justify-content: space-between;
	padding: 8px 18px;
  background-color: #f5f5f5;
	border: 1px solid colors.$border-color-disabled;
  border-radius: 8px;
}

.toggle-label {
  font-size: 12px;
  font-weight: 400;
  color: colors.$primary;
}