@use '~@myjobglasses/account-ui/mixins/colors';
@import '../input';

.text-input {
  @include input(false, false);
  &__main {
    border-radius: 4px;

    &__counter-buttons {
      position: absolute;
      right: 2px;
      display: flex;
      flex-direction: row;

      button {
        width: 40px;
        height: 44px;
        cursor: pointer;
        background-color: white;
        border: none;
        border-left: 1px solid #DFDFDF;
        transition: transform 0.1s ease-in-out;

        span {
          font-size: 26px;
          color: #5A657C;
        }

        &:disabled {
          cursor: not-allowed;
          background-color: #f0f0f0;
        }
      }
    }
  }
}
