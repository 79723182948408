@use '~@myjobglasses/account-ui/mixins/colors';

.text-button {
  display: flex;
  flex: 1 0;
  flex-direction: column;
  gap: 24px;
  align-items: center;
  justify-content: center;
  height: 148px;
  padding: 16px 24px;
  font-size: 14px;
  color: #09193B;
  background-color: transparent;
  border: 1px solid #DFDFDF;
  border-radius: 8px;
  transition: background-color 200ms ease-in-out;

  &:hover {
    background-color: colors.$light-grey;
  }

  &--selected {
    background-color: #E4ECF3;
    border-color: colors.$primary;
    &:hover {
      background-color: #E4ECF3;
    }
  }

  &__label {
    font-size: 14px;
    font-weight: 600;
  }

  &__content {
    font-size: 12px;
    font-weight: 400;
    text-align: center;
  }

  &__icon {
    stroke: colors.$primary;
    stroke-width: 1.5;
  }
}
